// FontAwesome, with manual icon selection
// as tree-shaking won't work, when we dynamically assign
// the icons in components

import { library } from '@fortawesome/fontawesome-svg-core'
import { faDiscord } from '@fortawesome/free-brands-svg-icons/faDiscord'
import { faSlack } from '@fortawesome/free-brands-svg-icons/faSlack'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faDiscord, faSlack, faTwitter)

export default FontAwesomeIcon;