












import Vue from "vue";
import AppBar from "@/components/AppBar.vue"
import AppFooter from "@/components/AppFooter.vue"

export default Vue.extend({
  name: "App",
  components: {
    AppBar,
    AppFooter,
  },

  data: () => ({
    //
  }),
  
  metaInfo: {
    title: 'Community Knowledge Platform',
    titleTemplate: 'Arkive.ai - %s',
  }
});
