import Vue from "vue";
import VueMeta from "vue-meta";
import { MetaInfo } from 'vue-meta';

Vue.use(VueMeta)

declare module "vue/types/vue" {
    interface Vue {
        metaInfo(): MetaInfo | (() => MetaInfo)
    }
}
