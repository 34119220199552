


















































import Vue from "vue";
import SectionContainer from "@/components/SectionContainer.vue"
import FeatureCard from "@/components/FeatureCard.vue"
import IntegrateCard from "@/components/IntegrateCard.vue"
interface featureData {
  title: string
  description: string
}

interface integrateData {
  title: string
  iconName: string
  prefix: string
}

export default Vue.extend({
  name: "Home",

  components: {
    FeatureCard,
    IntegrateCard,
    SectionContainer
  },

  data: () => ({
    features: [{
      title: "Find Answers",
      description: "Community members can search previously asked questions to find answers that were shared before"
    },
    {
      title: "Automatic FAQ",
      description: "Automatically generate FAQs from the questions your community asks frequently"
    },
    {
      title: "Create a Knowledgebase",
      description: "Quickly create a public or private knowledebase/wiki from past discussions in the community chat"
    },
    {
      title: "See Related Knowledge",
      description: "Comunity members can browse related questions and answers"
    }] as featureData[],

    integrate: [{
      title: "Discord",
      prefix: "fab",
      iconName: "discord"
    },
    {
      title: "Slack",
      prefix: "fab",
      iconName: "slack"
    }] as integrateData[]
  }),

  metaInfo: {
    title: 'Community Knowledge Platform',
  }
});
