import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import FontAwesomeIcon from "./plugins/fontawesome";
import "./plugins/vue-meta";

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false;
new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
