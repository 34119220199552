








































import Vue from "vue";

interface linkData {
  text: string
  to: string
}

interface linkSection {
  title: string
  links: linkData[]
}

interface socialData {
  aria: string
  prefix: string
  iconName: string
  link: string
}

export default Vue.extend({
  name: "AppFooter",

  data: () => ({
    links: [{
      title: "",
      links: []
    },
    {
      title: "",
      links: []
    },
    {
      title: "About Us",
      links: [{
        text: "Home",
        to: "Home",
      }]
    },
    {
      title: "Join Us",
      links: [{
        text: "Join Waiting List",
        to: "Join",
      }]
    }] as linkSection[],

    socials: [{
      aria: "Arkive on Twitter",
      prefix: "fab",
      iconName: "twitter",
      link: "https://twitter.com/ArkiveAI",
    }] as socialData[],
  }),

  computed: {
    year: function() {
      return new Date().getFullYear();
    }
  }
});
