












import Vue from "vue";
export default Vue.extend({
  name: "IntegrateCard",
  props: {
    prefix: String,
    iconName: String,
    title: String
  }
});
