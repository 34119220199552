












import Vue from "vue";
export default Vue.extend({
  name: "SectionContainer",
  props: {
    title: String,
  },
});
