






























import { mdiClipboardText } from '@mdi/js'
import Vue from "vue";

export default Vue.extend({
  name: "AppBar",

  data: () => ({
    mdiClipboardText
  }),

  computed: {
    collapsed: function() {
      return this.$vuetify.breakpoint.xs;
    }

  }
});
